import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-murfreesboro-tennessee.png'
import image0 from "../../images/cities/scale-model-of-murfreesboro-escape-rooms-in-murfreesboro-tennessee.png"
import image1 from "../../images/cities/scale-model-of-discovery-center-at-murfree-spring-in-murfreesboro-tennessee.png"
import image2 from "../../images/cities/scale-model-of-cannonsburgh-village-in-murfreesboro-tennessee.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Murfreesboro'
            state='Tennessee'
            image={image}
            lat='35.8456213'
            lon='-86.39026999999999'
            attractions={ [{"name": "Murfreesboro Escape Rooms", "vicinity": "1970 Medical Center Pkwy, Murfreesboro", "types": ["point_of_interest", "establishment"], "lat": 35.8585982, "lng": -86.42879040000003}, {"name": "Discovery Center at Murfree Spring", "vicinity": "502 SE Broad St, Murfreesboro", "types": ["museum", "point_of_interest", "establishment"], "lat": 35.8387031, "lng": -86.387068}, {"name": "Cannonsburgh Village", "vicinity": "312 S Front St, Murfreesboro", "types": ["museum", "park", "point_of_interest", "establishment"], "lat": 35.8438439, "lng": -86.39618239999999}] }
            attractionImages={ {"Murfreesboro Escape Rooms":image0,"Discovery Center at Murfree Spring":image1,"Cannonsburgh Village":image2,} }
       />);
  }
}